import $ from 'jquery'; 

// click event for scroll to top button
$('.scrollToTop').click(function(){
  // jquery animate scroll to top of page
	$('html, body').animate({scrollTop : 0},800);
  // scroll to top button
  setTimeout(hideBackToTop, 500)
	return false;
});

// is back to scroll visible
var isScrollingUp = false
// last checked value of scroll
var lastScroll = 0
// the distance threshold used in checks
var scrollThreshhold = 200
// html node of backtotop
var backToTop = $('.back-to-top')

// add visibility to scroll to top and keep track of it
function showBackToTop() {
  backToTop.addClass('back-to-top--visible')
  isScrollingUp = true
}

// remove visibility to scroll to top and keep track of it
function hideBackToTop() {
  backToTop.removeClass('back-to-top--visible')
  isScrollingUp = false
}

// check if we are scrolling up periodically
function isScrollingUpCheck() {
  // if current scroll is below previous value minus threshold and not less than half of a window height
  if(window.scrollY < lastScroll - scrollThreshhold && window.scrollY > window.innerHeight / 2) {
    // show back to top
    showBackToTop()
    // take a note of current scroll value
    lastScroll = window.scrollY
  }

  // if current scroll is above half of window height or more than last noted value of scroll
  if(window.scrollY < window.innerHeight/2 || window.scrollY > lastScroll) {
    // hide back to top
    hideBackToTop()

    // take a note of current scroll value
    lastScroll = window.scrollY
  }

  // check every 200ms for scroll value
  setTimeout(function() {
    requestAnimationFrame(isScrollingUpCheck)
  }, 200);
}
// start scrolling checks
isScrollingUpCheck();
  



