import $ from 'jquery';
import {lory} from 'lory.js';

/*
  Video Page Slider
 */
document.addEventListener('DOMContentLoaded', function () {
    // attempt to get .js_slider element
    var slider = document.querySelector('.js_slider');
    if(slider) {
      // if slider exists

      // navigation elements
      var dot_count         = slider.querySelectorAll('.js_slide').length;
      var dot_container     = slider.querySelector('.video-tile__control-dots');
      var dot_list_item     = document.createElement('li');
      dot_list_item.className = 'video-tile__control-dots-item'
      var activeClass = 'video-tile__control-dots-item video-tile__control-dots-item--active'
      var inactiveClass = 'video-tile__control-dots-item'

      // event listener function to handle different events for carousel
      function handleDotEvent(e) {
          if (e.type === 'before.lory.init') {
            for (var i = 0, len = dot_count; i < len; i++) {
              var clone = dot_list_item.cloneNode();
              dot_container.appendChild(clone);
            }
            dot_container.childNodes[0].className = activeClass;
          }
          if (e.type === 'after.lory.init') {
            for (var i = 0, len = dot_count; i < len; i++) {
              dot_container.childNodes[i].addEventListener('click', function(e) {
                dot_navigation_slider.slideTo(Array.prototype.indexOf.call(dot_container.childNodes, e.target));
              });
            }
          }
          if (e.type === 'after.lory.slide') {
            for (var i = 0, len = dot_container.childNodes.length; i < len; i++) {
              dot_container.childNodes[i].className = inactiveClass;
            }
            dot_container.childNodes[e.detail.currentSlide - 1].className = activeClass;
          }
          if (e.type === 'on.lory.resize') {
              for (var i = 0, len = dot_container.childNodes.length; i < len; i++) {
                  dot_container.childNodes[i].className = inactiveClass;
              }
              dot_container.childNodes[0].className = activeClass;
          }
      }
      // event listeners for carousel dots
      slider.addEventListener('before.lory.init', handleDotEvent);
      slider.addEventListener('after.lory.init', handleDotEvent);
      slider.addEventListener('after.lory.slide', handleDotEvent);
      slider.addEventListener('on.lory.resize', handleDotEvent);


      // initialize lory carousel
      var dot_navigation_slider = lory(slider, {
          infinite: 1,
          enableMouseEvents: true
      });

      // set interval for automatic scrolling
      setInterval(function() {
        // check if currently user hovers the carousel, if not then scroll to next item
        if(!$(slider).is(':hover') && !$(dot_container).is(':hover')) {
          dot_navigation_slider.next()
        }
      }, 5000)

    }

});
